<template>
  <ChatDesktop v-if="isDesktopSize">
    <slot />
  </ChatDesktop>
  <ChatMobile v-else>
    <slot />
  </ChatMobile>
</template>

<script lang="ts" setup>
import ChatDesktop from './chat-desktop.vue';
import ChatMobile from '@/shared/layouts/chat-mobile.vue';

const { isDesktopSize } = useWindowSize();

useCanonicalUrl();
</script>
